.modal-open .modal {
  display: grid;
  justify-content: center;
  align-items: center;
}
.modal-header {
  padding: 10px 10px 0px 10px;
}
.modal-title {
  font-size: 18px !important;
}
.modal-body {
  height: calc(100% - 5px);
  overflow-y: auto; /*give auto it will take based in content */
}
.MuiExpansionPanelDetails-root {
  display: block !important;
  padding: 0px !important;
}
.MuiExpansionPanelSummary-root.Mui-expanded {
  min-height: 48px !important;
  background: #8080804a;
}
.MuiExpansionPanelSummary-content.Mui-expanded {
  margin: 0px !important;
}
.root {
  width: 100%;
}
.heading {
  font-size: 13px !important;
  flex-basis: 33.33%;
  flex-shrink: 0;
  color: black;
}

// .ag-grid-table {
//   .ag-header-cell {
//     border-top: none !important;
//   }
// }
