@import "../../Constants/color.scss";
.login {
  height: 100vh;
  max-width: 100% !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 0px;
  background-color: #fafafa;
  background-image: url("../../icons/Aviation-airport-Boeing-737-aircraft_2560x1600-min.jpg");
  // background-image: url("../../icons/login_bg.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .paper {
    margin-left: 35%;
    width: 440px;
    height: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
    border-radius: 15px;
    padding: 25px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    .top-login {
      display: flex;
      justify-content: center;
      position: relative;
      background-color: $white !important;
      .avatar {
        background-color: $red !important;
        width: 60px;
        height: 60px;
        position: absolute;
        top: -60px;
      }
      .MuiTypography-h5 {
        margin-top: 20px;
        font-family: cursive;
        font-size: 25px;
      }
    }
    p {
      color: $red;
      margin-top: 20px;
      font-size: 13px;
    }
    .form {
      width: 100%;
      margin-top: 1px;
    }
    .submit {
      margin: 3px 0px 5px !important;
      // background: -webkit-linear-gradient(right, #21d4fd, #0091ea, #1abb9c);
      background: -webkit-linear-gradient(right, #27548e, #3d70ab, #27548e);
      height: 40px;
      border-radius: 20px;
      font-size: 12px;
    }
    .disable {
      color: white;
      background-color: #3f51b5d6;
      pointer-events: none;
      box-shadow: none;
      cursor: default;
    }
    .logo-img {
      display: flex;
      justify-content: center;
      margin: 45px;
      img {
        width: 300px;
      }
    }
    img {
      width: 17px;
    }
    .MuiOutlinedInput-input {
      font-size: 12px;
    }
    .MuiInputLabel-outlined {
      color: #000000a8;
      font-size: 12px;
    }
    a {
      font-size: 12px;
    }
  }
}
.contact {
  font-size: 16px;
  margin-top: 25px;
  text-align: center;
}
.login-container{
  margin-top: 0;
  margin-bottom: 1rem;
}
.login-container .input-container{
  margin: 1rem;
}
.login-container .input-container label{
    font-size: 1.5rem;
    color: #3d70ab;
    margin: 0.8rem 0;
}
.login-container 
.input-container input{
  width: 100%;
  padding: 0.8rem 1rem;
  border-radius: 6px;
  border:1px solid #27548e;
  font-size: 1.3rem;
}
@media (max-height: 580px) {
  .login {
    .paper {
      height: 350px;
    }
  }
}
